<template>
    <div>
        <!-- error handelr -->
        <b-alert v-height-fade.appear fade :show="showDismissibleAlert" @dismissed="showDismissibleAlert = false" variant="danger">
            <h4 class="alert-heading">
                Alert
            </h4>
            <div class="alert-body">
                   <ul v-for="(values , index) in errors_back" :key="index">
                    <li v-for="(value , valIndex) in values" :key="valIndex">{{value}}</li>
                </ul>
            </div>
        </b-alert>
        <!-- start data table -->
        <b-card title="رصيد سابق">
            <b-row>
                <!-- sorting -->
                <b-col md="4" class="my-1">
                    <b-form-group :label="$t('Table_Head.sortable')" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="sortBySelect" class="mb-0">
                        <b-input-group size="sm">
                            <b-form-select id="sortBySelect" @change="sortingTable" v-model="sortBy" :options="sortOptions" class="w-50">
                            </b-form-select>
                            <b-form-select @change="sortingTable" v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-50">
                                <option :value="false">
                                    {{$t('Product_Index.ASC')}}
                                </option>
                                <option :value="true">
                                   {{$t('Product_Index.DESC')}}
                                </option>
                            </b-form-select>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <!-- filter -->
                <b-col md="4" class="my-1">
                    <b-form-group :label="$t('Product_Index.Filter')" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput" class="mb-0">
                        <b-input-group size="sm">
                            <b-form-input id="filterInput" @change="sortingTable" v-model="filter" type="search" :placeholder="$t('Product_Index.Search')" />
                            <b-input-group-append>
                                <b-button variant="primary" @click="fetchData">
                                    {{$t('Table_Head.Search')}}
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col cols='1' class="my-1">
                    <!-- print and save pdf & excel -->
                    <div class="ml-auto mr-2">
                        <b-dropdown style="height:2.142rem; line-height:0.5" v-ripple.400="'rgba(113, 102, 240, 0.15)'" right variant="primary" size='sm' class="dropdown-icon-wrapper">
                            <template #button-content><feather-icon icon="PrinterIcon" size="16" class="align-middle" />
</template>
              <download-excel :data="exportItems">
                <b-dropdown-item>
                  <feather-icon icon="DownloadIcon" /> Download Excel</b-dropdown-item>
              </download-excel>
              <b-dropdown-divider />
              <b-dropdown-item @click="genPDF">
                <feather-icon icon="DownloadIcon" /> Save PDF</b-dropdown-item>
              <b-dropdown-divider />
              
            </b-dropdown>
          </div>
        </b-col>
        
           <b-col md="2" class="my-1">
          <!-- add new Brand button -->
          <div class="d-flex align-items-center justify-content-end">
            <b-button style="height:2.142rem; line-height:0.5" variant="primary" :to="{path: '/transaction/add'}">
              <span class="text-nowrap">{{$t('Product_Index.Add_Payout')}}</span>
            </b-button>
          </div>
        </b-col>
                <!-- actions -->
                <b-col cols="12">
                    <b-table id="TransactionsTable"  ref="admint" striped hover responsive show-empty  :busy.sync='isBusy' class="position-relative" :per-page="perPage"  :current-page="currentPage" :items="fetchData" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
<template #cell(logo)="data">
    <b-avatar :src="data.value" />
</template>
<template #cell(desc)="data">
    <b-card-text v-html="data.value">
    </b-card-text>
</template>
<template #cell(actions)="items">
    <div class="demo-inline-spacing">
        <!-- show payout -->
        <b-button style='padding:7px; margin:6px; width:35px; height:35px;' class="btn-icon rounded-circle" size="sm" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" type="submit" :to="{path: '/transaction/show/' + items.item.id}">
            <feather-icon icon="EyeIcon" style="width:18px; height:18px;" />
        </b-button>
    </div>
</template>
                    </b-table>
                </b-col>
                
            </b-row>
            <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">{{$t('Product_Index.Per_Page')}}</label>
          <b-form-select id="perPageSelect" @change="fetchData" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
        </b-form-group>
        <!-- pagination -->
        <div>
          <b-pagination @input="fetchData()" aria-controls="TransactionsTable" v-model="currentPage" :total-rows="rows" :per-page="perPage" align="center" class="my-0" />
        </div>
      </b-card-body>
        </b-card>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                per: null,
                showDismissibleAlert: false,
                errors_back: [],
                errors_delete: {},
                perPage: 25,
                pageOptions: [25, 50, 100],
                totalRows: 1,
                isBusy: false,
                currentPage: 1,
                sortBy: 'id',
                sortDesc: true,
                sortDirection: 'desc',
                filter: "",
                filterOn: [],
                infoModal: {
                    id: 'info-modal',
                    title: '',
                    content: '',
                },
                // table fildes
                fields: [{
                        key: 'id',
                        label: this.$t('Table_Head.ID'),
                        sort_by: true,
                    },
                    {
                        key: 'vendor',
                        label: this.$t('Table_Head.Vendor'),
                    },
                    {
                        key: 'status',
                        label: this.$t('Table_Head.Status'),
                    },
                    {
                        key: 'type',
                        label: this.$t('Table_Head.Type'),
                    },
                    {
                        key: 'price',
                        label: this.$t('Table_Head.Price'),
                    },
                    {
                        key: 'actions',
                        label: this.$t('Table_Head.Actions'),
                    },
                ],
                items: [],
                exportItems: [],
                // permissions:[]
            }
        },
        created() {
            // request Coupons index
            this.fetchData()
        },
        computed: {
            sortOptions() {
                // Create an options list from our fields
                return this.fields
                    .filter(f => f.sort_by)
                    .map(f => ({
                        text: f.label,
                        value: f.key
                    }))
            },
            rows() {
                return this.totalRows
            },
        },
        watch: {
            "sortDirection": function() {
                this.sortingTable()
            },
            "sortBy": function() {
                this.sortingTable()
            },
            'filter': function() {
                if (this.filter == '' || this.filter == null) {
                    this.fetchData()
                }
            }
        },
        methods: {
            // fetch data of Coupons
            fetchData() {
                var url = null
                if (this.filter == '') {
                    url = 'payouts/status/paid/' + this.perPage.toString() + '/sortBy-' + this.sortBy + '-' + this.sortDirection + '?page=' + this.currentPage
                } else {
                    url = 'payouts/status/paid/' + this.perPage.toString() + '/sortBy-' + this.sortBy + '-' + this.sortDirection + '/' + this.filter + '?page=' + this.currentPage
                }
                const promise = axios.get(url)
                return promise.then((result) => {
                    const data = result.data.data.data
                    this.totalRows = result.data.data.meta.total
                    this.items.length = 0
                    for (var index in data) {
                        if (data[index].vendor != null) {
                            this.items.push({
                                id: data[index].id,
                                vendor: data[index].vendor.storename,
                                status: data[index].status,
                                type: data[index].type,
                                price: data[index].price,
                            })
                        } else {
                            this.items.push({
                                id: data[index].id,
                                vendor: 'undefined',
                                status: data[index].status,
                                type: data[index].type,
                                price: data[index].price,
                            })
                        }
                    }
                    this.items = [...new Map(this.items.map(item => [item.id, item])).values()]
                    this.exportItems = this.items
                    this.$refs.admint.refresh();
                    return this.items
                }).catch((err) => {
                    this.errors_back.length = 0
                    if (err.response.data.data != null) {
                        if (this.isString(err.response.data.data)) {
                            this.errors_back.push({
                                error: err.response.data.data
                            })
                        } else {
                            this.errors_back = err.response.data.data
                        }
                        this.showDismissibleAlert = true
                    } else {
                        this.errors_back = []
                        this.errors_back.push({
                            error: 'internal server error'
                        })
                        this.showDismissibleAlert = true
                    }
                });
            },
            info(item, index, button) {
                this.infoModal.title = `Row index: ${index}`
                this.infoModal.content = JSON.stringify(item, null, 2)
                this.$root.$emit('bv::show::modal', this.infoModal.id, button)
            },
            resetInfoModal() {
                this.infoModal.title = ''
                this.infoModal.content = ''
            },
            // search function
            // sort function
            sortingTable() {
                this.currentPage = 1
                if (this.sortDesc == true) {
                    this.sortDirection = 'desc'
                } else {
                    this.sortDirection = 'asc'
                }
                var url = null
                if (this.filter == '') {
                    url = 'payouts/status/paid/' + this.perPage.toString() + '/sortBy-' + this.sortBy + '-' + this.sortDirection + '?page=' + this.currentPage
                } else {
                    url = 'payouts/status/paid/' + this.perPage.toString() + '/sortBy-' + this.sortBy + '-' + this.sortDirection + '/' + this.filter + '?page=' + this.currentPage
                }
                const promise = axios.get(url)
                return promise.then((result) => {
                    const data = result.data.data.data
                    this.totalRows = result.data.data.meta.total
                    this.items.length = 0
                    for (var index in data) {
                        this.items.push({
                            id: data[index].id,
                            vendor: data[index].vendor.storename,
                            status: data[index].status,
                            type: data[index].type,
                            rate: data[index].rate,
                            price: data[index].price,
                        })
                    }
                    this.items = [...new Map(this.items.map(item => [item.id, item])).values()]
                    this.exportItems = this.items
                    this.$refs.admint.refresh();
                    return this.items
                }).catch((err) => {
                    this.errors_back.length = 0
                    if (err.response.data.data != null) {
                        if (this.isString(err.response.data.data)) {
                            this.errors_back.push({
                                error: err.response.data.data
                            })
                        } else {
                            this.errors_back = err.response.data.data
                        }
                        this.showDismissibleAlert = true
                    } else {
                        this.errors_back = []
                        this.errors_back.push({
                            error: 'internal server error'
                        })
                        this.showDismissibleAlert = true
                    }
                });
            },
            printEle() {
                window.print();
            },
            genPDF() {
                const { jsPDF } = require("jspdf");
                const doc = new jsPDF()
                let print_body = [];
                for (let index = 0; index < this.items.length; index++) {
                    print_body[index] = [this.items[index].id, this.items[index].vendor, this.items[index].status, this.items[index].type, this.items[index].rate, this.items[index].price];
                }
                doc.autoTable({
                    head: [
                        ['ID', 'Vendor', 'Status', 'Type', 'Rate', 'Price']
                    ],
                    body: print_body
                })
                doc.save('table.pdf')
            },
            isString(value) {
                return typeof value === 'string' || value instanceof String;
            },
        },
    }
</script>
 
